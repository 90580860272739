import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import BackgroundImage from 'gatsby-background-image'
import TagLine from "../components/tag-line";
import FundProgress from "../components/fund-progress";
import VisionAndStats from "../components/vision-and-stats";
import TestimonialSlider from "../components/testimonial-slider";
import Partner from "../components/partner";
import Img from "gatsby-image"

import stats from "../data/stats.json"

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Home" keywords={[`missoula`, `sovereign hope`, `church`]} />
    <div id="home">
      <BackgroundImage
        Tag="section"
        fluid={data.homepageHeader.childImageSharp.fluid}
      >
        <div id="main">
          <div id="video">
            <div id="wrapper">
              <iframe
                title="video"
                src="https://www.youtube.com/embed/XxnyZSsOR7A?modestbranding=1&rel=0&iv_load_policy=3"
                frameBorder="0"
              ></iframe>
            </div>
          </div>
          <div id="text">
            <TagLine
              center={false}
              message={"Support a movement for Missoula’s good by donating to Sovereign Hope Church’s building fund."}
              matching={false}
            />
          </div>
        </div>
      </BackgroundImage>
    </div>
    <FundProgress currentAmount={stats.currentAmount} />
    <VisionAndStats />
    <TestimonialSlider
      slides={[
        {
          fluidImage: data.slideOne.childImageSharp.fluid,
          quoteText: "We are excited for the vision God has given Sovereign Hope in transitioning to a new building that will help us better serve our community in Missoula.”",
          quoteAuthor: "Jordan & Kaylee Hines",
          quoteColorClass: "yellow",
          class: 'hines',
        },
        {
          fluidImage: data.slideTwo.childImageSharp.fluid,
          quoteText: "We as a family are committing to grow in our relationship with God and as a result of that, love those around us in a more eternal, life-giving way.”",
          quoteAuthor: "Matt & Whitney Caldarone",
          quoteColorClass: "pink",
          class: 'caldarone',
        },
        {
          fluidImage: data.slideThree.childImageSharp.fluid,
          quoteText: "As a small business consultant in Missoula, I want the gospel to inform every aspect of how I view my life and work.”",
          quoteAuthor: "Tim Costin",
          quoteColorClass: "green",
          class: 'costin',
        }
      ]}
    />
    <Partner fluidImage={data.partnerChurch.childImageSharp.fluid} />
    <div id="members-row">
      <Img
        fixed={data.membersRow.childImageSharp.fixed}
      />
    </div>
  </Layout>
)

export const query = graphql`
  query {
    homepageHeader: file(relativePath: { eq: "homepage-header.png" }) {
      childImageSharp {
        fluid(maxHeight: 736, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    },
    slideOne: file(relativePath: { eq: "testimonial-slide-1.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    },
    slideTwo: file(relativePath: { eq: "testimonial-slide-2.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    },
    slideThree: file(relativePath: { eq: "testimonial-slide-3.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    },
    partnerChurch: file(relativePath: { eq: "partner-church.png" }) {
      childImageSharp {
        fluid(maxWidth: 674, quality: 90) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    },
    membersRow: file(relativePath: { eq: "members-row.png" }) {
      childImageSharp {
        fixed(quality: 100, height: 256, width: 2815) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
  }
`

export default IndexPage
