import React from "react"
import {Helmet} from "react-helmet";
import Img from "gatsby-image"

import Slider from "react-slick";

import "./main.css"

const CustomNextArrow = ({ className, style, onClick }) => (
  <div
    className={className}
    style={{ display: "block" }}
    onClick={onClick}
  />
)

const CustomPrevArrow = ({ className, style, onClick }) => (
  <div
    className={className}
    style={{ display: "block" }}
    onClick={onClick}
  />
)

const TestimonialSlider = ({slides}) => (
  <div id="testimonial-slider">
    <Helmet>
      <link rel="stylesheet" type="text/css" charset="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
      <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
    </Helmet>
    <Slider
      infinite={true}
      speed={500}
      autoplay={true}
      autoplaySpeed={18000}
      dots={true}
      fade={true}
      pauseOnHover={true}
      nextArrow={<CustomNextArrow />}
      prevArrow={<CustomPrevArrow />}
    >
      {slides.map((item, key) =>
        <div id="slide" key={key} className={item.class + " background-pink"}>
          <div id="quote" className={"poppins small " + item.quoteColorClass + (item.quoteOnRight !== undefined && item.quoteOnRight ? " right" : "") }>
            <p>{item.quoteText}</p>
            <p>{item.quoteAuthor}</p>
          </div>
          <Img
            fluid={item.fluidImage}
            critical={true}
          />
        </div>
      )}
    </Slider>
  </div>
)

export default TestimonialSlider
