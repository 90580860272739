import React from "react"
import PropTypes from "prop-types"

import "./main.css"

const FundProgress = ({currentAmount}) => (
  <div id="fund-progress" className="poppins massive light background-blue">
    <div className="center auto-margin width-medium">
      <p><b>${currentAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</b> of $2 million raised</p>
      <div
        id="progress-bar"
      >
        <div
          id="progress"
          className="background-pink"
          style={{
            width: `${( currentAmount > 2000000 ? 2000000 : currentAmount )/2000000*100}%`,
          }}
        ></div>
      </div>
      <div id="details" className="medium">
        <p className="float-left no-margin-bottom"><b>${currentAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} RAISED</b></p>
        <p className="float-right no-margin-bottom"><b>GOAL: $2 MILLION</b></p>
      </div>
    </div>
  </div>
)

FundProgress.propTypes = {
  currentAmount: PropTypes.number,
}

FundProgress.defaultProps = {
  currentAmount: 0,
}

export default FundProgress

