import React from "react"
import { Link } from "gatsby";
import Img from "gatsby-image"

const Partner = ({fluidImage}) => {
  return (
    <div id="partner-section">
      <div id="container" className="poppins light large">
        <div id="text">
          <h2 id="heading" className="color-blue">Because the gospel has influence, you have influence.</h2>
          <p>What will your legacy be? Will you commit to build a gospel legacy with us in Missoula?</p>
          <Link className="button background-blue color-white small" to="/partner"><b>Partner with Us</b></Link>
        </div>
        <div id="image">
          <Img
            fluid={fluidImage}
          />
        </div>
      </div>
    </div>
  )
}

export default Partner

