import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby";
import countdown, { MONTHS, DAYS } from "countdown";
import stats from "../data/stats.json"

const VisionAndStats = ({ donors, averageDonation, endDate, percentRaised }) => {

  useEffect(() => {
    var flexFont = () => {
      var divs = document.getElementsByClassName("flex-font");
      for (var i = 0; i < divs.length; i++) {
        var resetMinHeight = divs[i].style.minHeight;
        var resetParentWidth = divs[i].parentElement.style.width;

        divs[i].style.height = null;
        divs[i].style.minHeight = "55%";
        divs[i].parentElement.style.width = "100%";


        divs[i].style.fontSize = '0px';
        divs[i].style.lineHeight = '0px';

        var maxWidth = divs[i].parentElement.offsetWidth
        var maxHeight = divs[i].offsetHeight

        divs[i].style.minHeight = resetMinHeight;
        divs[i].parentElement.style.width = resetParentWidth;

        var currentSize = 0;

        while(divs[i].offsetWidth <= maxWidth && divs[i].offsetHeight <= maxHeight) {
          divs[i].style.fontSize = currentSize + 'px';
          divs[i].style.lineHeight = currentSize + 'px';
          currentSize++
        }
        currentSize--

        if (divs[i].offsetWidth > maxWidth) {
          currentSize -= (divs[i].offsetHeight / maxHeight) * 25
        }

        if (divs[i].offsetHeight > maxHeight) {
          currentSize -= 25
        }

        divs[i].style.fontSize = currentSize + 'px';
        divs[i].style.lineHeight = currentSize + 'px';
        divs[i].style.height = "55%";
      }
    };

    flexFont();

    window.onresize = (event) => {
      flexFont();
    };
    return () => {

    }
  })

  let timespan = countdown(null, endDate, MONTHS|DAYS);

  return (
    <div id="vision-and-stats">
      <div id="container" className="poppins light large">
        <div id="text">
          <h2 id="heading" className="color-blue">Missoula’s need for the gospel has never been greater.</h2>
          <p>As Missoula grows rapidly, we want our church to be the center of a gospel movement in Missoula.</p>
          <p>We've secured our building and a two year interest only period for financing. This means that the money we raise right now helps us lower our mortgage payments and fund some of the essential upgrades and renovations.</p>
          <Link className="button background-blue color-white small" to="/the-vision"><b>See the Vision</b></Link>
        </div>
        <div id="stats">
          <div id="donors" className="background-yellow">
            <div>
              <p className="poppins bold flex-font">{donors}</p>
              <p className="poppins massive bold">donors</p>
            </div>
          </div>
          <div id="avg" className="background-pink">
            <div>
              <p className="poppins bold flex-font">${averageDonation}</p>
              <p className="bold">avg. donation</p>
            </div>
          </div>
          <div id="remaining" className="background-blue">
            <div id="bounding">
              <div className="float-left">
                <p id="text" className="large bold">remaining</p>
              </div>
              <div id="countdown" className="float-right bold">
                <div>
                  <p id="months-count" className="massive">{ timespan.months }</p>
                  <p id="months-label" className="medium">{ timespan.months === 1 ? "month" : "months" }</p>
                  <p id="days-count" className="massive">{ timespan.days }</p>
                  <p id="days-label" className="large">{ timespan.days === 1 ? "day" : "days" }</p>
                </div>
              </div>
            </div>
          </div>
          <div className="background-green">
            <div>
              <p className="poppins ridiculous bold flex-font">{percentRaised}%</p>
              <p className="poppins massive bold no-margin-bottom">raised</p>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

VisionAndStats.propTypes = {
  donors: PropTypes.number,
  averageDonation: PropTypes.number,
  endDate: PropTypes.object,
  percentRaised: PropTypes.number,
}

VisionAndStats.defaultProps = {
  donors: stats.numDonors,
  averageDonation: stats.averageDonation,
  endDate: new Date('July 15, 2022'),
  percentRaised: stats.percentRaised,
}

export default VisionAndStats

